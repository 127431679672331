import { shopstoryGetStyleTag } from "@shopstory/core/react"
import React from "react"

import { InterfaceProvider } from "./src/context/InterfaceContext"
import { NewStoreProvider } from "./src/context/NewStoreContext"
import { QueryClient } from "@tanstack/react-query"
import { MedusaProvider } from "medusa-react"

const ONE_TRUST_SRC = process.env.GATSBY_ONE_TRUST || ""
const LUIGISBOX_TRACKER = process.env.LUIGISBOX_TRACKER || "LBX-583458"

/** @type {import('gatsby').GatsbySSR['onPreRenderHTML']} */
export const onPreRenderHTML = ({
  getHeadComponents,
  replaceHeadComponents,
  pathname,
}) => {
  const headComponents = getHeadComponents()
  const shopstoryStyleTagElement = shopstoryGetStyleTag()

  headComponents.push(
    React.cloneElement(shopstoryStyleTagElement, {
      key: "shopstory-styles",
    })
  )

  if (pathname.includes("shopstory-editor")) {
    replaceHeadComponents(headComponents)
    return
  }

  headComponents.push(
    React.createElement("script", {
      key: "onetrust-custom",
      type: "text/javascript",
      dangerouslySetInnerHTML: {
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        var consentSettings = {
          ad_storage: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
        };

        if (document.cookie?.includes?.('C0002%3A1')) {
          consentSettings.functionality_storage = "granted";
        }

        if (document.cookie?.includes?.('C0003%3A1')) {
          consentSettings.analytics_storage = "granted";
        }

        if (document.cookie?.includes?.('C0004%3A1')) {
          consentSettings.ad_storage = "granted";
          consentSettings.ad_personalization = "granted";
          consentSettings.ad_user_data = "granted";
        }

        if (document.cookie?.includes?.('C0002%3A1') && document.cookie?.includes?.('C0003%3A1') && document.cookie?.includes?.('C0004%3A1')) {
          consentSettings.personalization_storage = "granted";
          consentSettings.security_storage = "granted";
        }

        gtag('consent', 'default', {
          ...consentSettings,
          wait_for_update: 500
        });`,
      },
    })
  )

  headComponents.push(
    React.createElement("script", {
      key: "varify-custom",
      type: "text/javascript",
      dangerouslySetInnerHTML: {
        __html: `
        window.varify = window.varify || {};
        window.varify.iid = 2820;`,
      },
    })
  )

  headComponents.push(
    React.createElement("script", {
      async: true,
      key: "varify",
      src: "https://app.varify.io/varify.js",
      type: "text/javascript",
      charSet: "UTF-8",
    })
  )

  headComponents.push(
    React.createElement("script", {
      defer: true,
      async: true,
      key: "onetrust",
      src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
      type: "text/javascript",
      charSet: "UTF-8",
      "data-domain-script": ONE_TRUST_SRC,
    })
  )
  headComponents.push(
    React.createElement(
      "script",
      {
        key: "onetrust-optanon",
        type: "text/javascript",
      },
      ["function OptanonWrapper() { }"]
    )
  )

  headComponents.push(
    React.createElement("meta", {
      key: "google-site-verification",
      name: "google-site-verification",
      content: "cxyXBILpSnkdYxZzyemX6Y8URyxoUSJh0f-RLVsS7nY",
    })
  )

  headComponents.push(
    React.createElement("meta", {
      key: "google-site-verification",
      name: "google-site-verification",
      content: "i1sNyJDGRQRsAH0P3zjuO6X4U47b_HQI53PFjHxQIzo",
    })
  )

  headComponents.push(
    React.createElement("script", {
      defer: true,
      async: true,
      key: "luigisbox",
      src: `https://scripts.luigisbox.com/${LUIGISBOX_TRACKER}.js`,
      type: "text/javascript",
      charSet: "UTF-8",
    })
  )

  replaceHeadComponents(headComponents)
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 30000,
      refetchOnWindowFocus: false,
    },
  },
})

export const wrapPageElement = ({ element }) => {
  return (
    <MedusaProvider
      queryClientProviderProps={{ client: queryClient }}
      baseUrl={process.env.GATSBY_STORE_URL || "http://localhost:9000"}
      publishableApiKey={process.env.MEDUSA_PUBLISHABLE_API_KEY || ""}
    >
      <NewStoreProvider>{element}</NewStoreProvider>
    </MedusaProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  return <InterfaceProvider>{element}</InterfaceProvider>
}
