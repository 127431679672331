export const getBunnyImageSrc = (src: string) => {
  if (!src) {
    return src
  }

  if (src.includes("images.ctfassets.net/4g6al16haqoj")) {
    let newSrc = src.replaceAll(
      "images.ctfassets.net/4g6al16haqoj",
      "tekla.b-cdn.net"
    )

    if (newSrc.includes("&fl=progressive")) {
      newSrc = newSrc.replaceAll("&fl=progressive", "")
    }

    if (newSrc.includes("fm=jpg")) {
      newSrc = newSrc.replaceAll("fm=jpg", "fm=webp")
    }

    return newSrc
  }

  return src
}

export const getBunnyVideoSrc = (src: string) => {
  if (!src) {
    return src
  }

  if (src.includes("videos.ctfassets.net/4g6al16haqoj")) {
    return src.replaceAll(
      "videos.ctfassets.net/4g6al16haqoj",
      "tekla-videos.b-cdn.net"
    )
  }

  return src
}

export const getGatsbyImageBunnySrc = (gatsbyImageData: any) => {
  if (!gatsbyImageData) {
    return gatsbyImageData
  }

  return {
    ...gatsbyImageData,
    images: {
      ...gatsbyImageData.images,
      fallback: {
        ...gatsbyImageData.images.fallback,
        src: getBunnyImageSrc(gatsbyImageData.images.fallback.src),
        srcSet: getBunnyImageSrc(gatsbyImageData.images.fallback.srcSet),
      },
      sources: gatsbyImageData.images.sources.map((source: any) => ({
        ...source,
        srcSet: getBunnyImageSrc(source.srcSet),
      })),
    },
  }
}
