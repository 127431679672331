import React from "react"

import NotificationProvider from "./src/components/Notifications/NotificationProvider"
import CookieConsent from "./src/components/ui/CookieConsent"
import AccountProvider from "./src/context/AccountContext"
import { InterfaceProvider } from "./src/context/InterfaceContext"
import { PersonalizationProvider } from "./src/context/personalization-context"
import "./src/assets/css/main.css"
import { ThemeProvider } from "./src/Theme"
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.state?.disableScrollToTop) {
    return false
  }

  if (location.state?.scrollTo) {
    window.scrollTo(location.state.scrollTo.x, location.state.scrollTo.y)
    return false
  }

  return [0, 0]
}

export const wrapRootElement = ({ element }) => {

  return (
    <InterfaceProvider>
      <PersonalizationProvider>
        <NotificationProvider>
          <AccountProvider>
            {element}
          </AccountProvider>
          {
            // for some reason the CookieConsent doesn't get the themeprovider
            // given by the gatsby plugin
          }
          <ThemeProvider>
            <CookieConsent />
          </ThemeProvider>
        </NotificationProvider>
      </PersonalizationProvider>
    </InterfaceProvider>
  )
}

export { wrapPageElement } from "./gatsby-ssr"
