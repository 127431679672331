import * as React from "react"
import {
  CartProvider,
  useAddShippingMethodToCart,
  useCompleteCart,
  useCreateCart,
  useCreateLineItem,
  useCreatePaymentSession,
  useDeleteLineItem,
  useMedusa,
  useSetPaymentSession,
  useUpdateCart,
  useUpdateLineItem,
  useUpdatePaymentSession,
} from "medusa-react"
import type { Cart } from "@medusajs/medusa"
import { useLocation } from "@reach/router"
import customEndpoints from "../services/api"
import qs from "query-string"
import flagsmith from "flagsmith"
import { useCookies } from "react-cookie"

const DEFAULT_SHIPPING_PROFILE =
  process.env.GATSBY_DEFAULT_SHIPPING_PROFILE || "sp_01EWDSXNDRGJBC8SX9JB7EDYS2"

type CreateCartData = {
  context: {
    locale: string
    currency?: string
  }
  region_id?: string
  country_code?: string
}
export interface StoreContextType {
  cart: Readonly<Cart> | undefined
  createLineItem: ReturnType<typeof useCreateLineItem>
  removeLineItem: ReturnType<typeof useDeleteLineItem>
  updateLineItem: ReturnType<typeof useUpdateLineItem>
  addShippingMethod: ReturnType<typeof useAddShippingMethodToCart>
  clearCart: () => void
  updateCart: ReturnType<typeof useUpdateCart>
  startCheckout: ReturnType<typeof useCreatePaymentSession>
  pay: ReturnType<typeof useSetPaymentSession>
  completeCheckout: ReturnType<typeof useCompleteCart>
  totalItems: number
  freeShippingLimitThreshold: undefined | number
  createSplitOrderCarts: (updatedCart: Readonly<Cart>) => Promise<void>
  splittingCarts: boolean
  splitOrderCarts: Cart[]
  setRegion: (regionId: string, countryCode: string) => void
  createCartWithRegion: (regionId: string, countryCode: string) => void
  removeCouponCode: (couponCode: string) => void
  addCouponCode: (couponCode: string) => void
  noStoreModal: boolean
  setNoStoreModal: React.Dispatch<React.SetStateAction<boolean>>
  removeSplitOrderCarts: () => void
  updatePaymentSession: ReturnType<typeof useUpdatePaymentSession>
  isAboveFreeShippingLimit: boolean
}

const storeContext = React.createContext<StoreContextType | undefined>(
  undefined
)

export const NewStoreProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const medusa = useMedusa()
  const [cart, setCart] =
    React.useState<Omit<Cart, "refunded_total" | "refundable_amount">>()
  const [freeShippingLimitThreshold, setFreeShippingLimitThreshold] =
    React.useState()
  const location = useLocation()
  const [prevLocation, setPrevLocation] = React.useState("")
  const [checkInterval, setCheckInterval] = React.useState(0)
  const [gaChecked, setGaChecked] = React.useState(0)
  const [noStoreModal, setNoStoreModal] = React.useState(false)
  const [splittingCarts, setSplittingCarts] = React.useState(false)
  const [splitOrderCarts, setSplitOrderCarts] = React.useState<Cart[]>([])
  const [isAboveFreeShippingLimit, setIsAboveFreeShippingLimit] =
    React.useState(false)
  const [cookies, setCookies] = useCookies(["_medusa_region"])

  const createCart = useCreateCart({
    onSuccess: async ({ cart: cartUpdate }) => {
      await updateFreeShippingLimit(cartUpdate)
      setCart(cartUpdate)
    },
  })

  const updatePaymentSession = useUpdatePaymentSession(cart?.id || "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const updateCart = useUpdateCart(cart?.id || "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const addShippingMethod = useAddShippingMethodToCart(cart?.id || "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const startCheckout = useCreatePaymentSession(cart?.id || "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const pay = useSetPaymentSession(cart?.id || "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const completeCheckout = useCompleteCart(cart?.id || "")
  const totalItems =
    cart?.items?.map((i) => i.quantity).reduce((acc, curr) => acc + curr, 0) ||
    0

  const updateFreeShippingLimit = async (
    cart: Omit<Cart, "refunded_total" | "refundable_amount">
  ) => {
    if (!cart?.region_id) {
      return
    }

    // Get cache
    const data = localStorage.getItem(`pg:fs:tax:incl:${cart.region_id}`)

    let result = null

    const now = new Date()
    const nowUnix = parseInt(now.getTime().toFixed(0))

    if (data) {
      const regionCache = JSON.parse(data)

      if (regionCache.expiryDate > nowUnix && regionCache?.freeShippingLimit) {
        result = regionCache.freeShippingLimit
      }
    }

    if (!result && cart?.id) {
      result = await medusa.client.shippingOptions.list({
        region_id: cart.region_id,
      })

      if (!result?.shipping_options) {
        return null
      }

      const freeShippingLimit = result.shipping_options.reduce(
        (acc, nextValue) => {
          if (nextValue.profile_id !== DEFAULT_SHIPPING_PROFILE) {
            return acc
          }

          // If we find a free shipping limit, find the lowest requirement
          if (
            nextValue.metadata?.hide !== "true" &&
            (nextValue.amount === 0 || nextValue.price_incl_tax === 0)
          ) {
            const limit = nextValue.requirements.find(
              (r) => r.type === "min_subtotal"
            )

            if (typeof limit === "undefined") {
              acc = 0
            } else {
              if (typeof acc === "undefined" || acc > limit.amount) {
                acc = limit.amount
              }
            }
          }
          return acc
        },
        undefined
      )

      // Add 24 hours from today as expiry date
      let expiryDate = now.getTime() + 24 * 60 * 60 * 1000

      localStorage.setItem(
        `pg:fs:tax:incl:${cart.region_id}`,
        JSON.stringify({
          freeShippingLimit,
          expiryDate,
        })
      )

      setFreeShippingLimitThreshold(freeShippingLimit)
    } else {
      setFreeShippingLimitThreshold(result)
    }
  }

  const loadSplitOrderCarts = async (options) => {
    const parsedOptions = JSON.parse(options)

    const previousCart = parsedOptions?.previousCart

    if (previousCart) {
      if (
        previousCart.updated_at !== cart?.updated_at ||
        previousCart?.items.some(
          (item) =>
            item.updated_at !==
            cart?.items.find((el) => el.id === item.id).updated_at
        ) ||
        previousCart.total !== cart.total
      ) {
        localStorage.removeItem("medusa::cache::splitordercart")
        await initSplitOrder()
      } else {
        if (parsedOptions?.splitOrderCarts) {
          setSplitOrderCarts(parsedOptions.splitOrderCarts)
        } else {
          await initSplitOrder()
        }
      }
    } else {
      initSplitOrder()
    }
  }

  const initSplitOrder = async (updatedCart?: Readonly<Cart>) => {
    let createdCarts = []
    let total = 0

    createdCarts = await customEndpoints.splitOrder
      .split(updatedCart ? updatedCart : cart)
      .then(async ({ data }) => data?.carts)

    for (let i = 0; i < createdCarts.length; i++) {
      total = total + createdCarts[i].total
    }

    const originalTotal = updatedCart ? updatedCart.total : cart.total
    // Sometimes there is small difference (1) due to rounding up of the splitted cart if it has sales tax
    if (total === originalTotal || total === originalTotal + 1) {
      localStorage.setItem(
        "medusa::cache::splitordercart",
        JSON.stringify({
          splitOrderCarts: createdCarts,
          previousCart: cart,
        })
      )

      setSplitOrderCarts(createdCarts)
    } else {
      setSplitOrderCarts([])
    }
  }

  const createSplitOrderCarts = async (updatedCart: Readonly<Cart>) => {
    setSplittingCarts(true)
    const options = localStorage.getItem("medusa::cache::splitordercart")

    if (options) {
      await loadSplitOrderCarts(options)
    } else {
      await initSplitOrder(updatedCart)
    }

    setSplittingCarts(false)
  }

  const removeSplitOrderCarts = () => {
    localStorage.removeItem("medusa::cache::splitordercart")
    setSplitOrderCarts([])
  }

  React.useEffect(() => {
    let cancelled = false

    const fn = async () => {
      let { pct, currency, mpr, sct } = qs.parse(window.location.search)

      if (sct && !cart?.id) {
        if (Array.isArray(sct)) {
          sct = sct[0]
        }

        const { swap } = await medusa.client.client.request(
          "GET",
          `/store/swaps/${sct}/line-items`,
          {}
        )

        if (swap?.id) {
          setCart(swap.cart)
        }
      }

      // If we are loading a pos or mobilepay checkout load cart from query param instead of cache.
      let nonDefaultCart = pct || mpr

      if (nonDefaultCart && !cart?.id) {
        if (Array.isArray(nonDefaultCart)) {
          nonDefaultCart = nonDefaultCart[0]
        }

        const { cart } = await medusa.client.carts.retrieve(nonDefaultCart)

        if (cart.id) {
          setCart(cart)
        }
      }

      if ((!cart?.id && !nonDefaultCart && !sct) || cart?.completed_at) {
        const cached = localStorage.getItem("medusa::cache")

        if (
          cart?.completed_at &&
          location.pathname?.includes("checkout/payment")
        ) {
          return
        }

        if (cancelled) return

        if (cart?.completed_at || !cached || currency) {
          let createCartData: CreateCartData = {
            context: {
              locale: "en-US",
            },
          }

          if (currency) {
            if (Array.isArray(currency)) {
              currency = currency[0]
            }

            createCartData.context = {
              ...createCartData.context,
              currency,
            }
          }

          await createCart.mutateAsync(createCartData)
        } else {
          const existingCart = JSON.parse(cached)

          if (cancelled) return

          if (existingCart?.id) {
            const existingCartMedusa = await medusa.client.carts.retrieve(
              existingCart.id
            )

            if (!existingCartMedusa?.cart) {
              await createCart.mutateAsync({
                context: {
                  locale: "en-US",
                },
              })

              return
            }

            if (
              !existingCartMedusa.cart?.sales_channel_id ||
              existingCartMedusa.cart?.type !== "default"
            ) {
              await createCart.mutateAsync({
                context: {
                  locale: "en-US",
                },
              })
            } else {
              await updateFreeShippingLimit(existingCartMedusa.cart)
              setCart(existingCartMedusa.cart)
            }
          } else {
            await createCart.mutateAsync({
              context: {
                locale: "en-US",
              },
            })
          }
        }
      }
    }

    fn()

    return () => {
      cancelled = true
    }
  }, [cart])

  const createLineItem = useCreateLineItem(cart?.id ?? "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const removeLineItem = useDeleteLineItem(cart?.id ?? "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const updateLineItem = useUpdateLineItem(cart?.id ?? "", {
    onSuccess: ({ cart: cartUpdate }) => setCart(cartUpdate),
  })

  const clearCart = async () => {
    localStorage.removeItem("medusa::cache")

    await createCart.mutateAsync({})
  }

  React.useEffect(() => {
    const fn = async () => {
      if (cart?.completed_at) {
        await createCart.mutateAsync({
          context: {
            locale: "en-US",
          },
        })
      }
    }

    if (
      prevLocation.includes("checkout") &&
      !location.pathname.includes("checkout")
    ) {
      fn()
    }

    setPrevLocation(location.pathname)
  }, [location])

  React.useEffect(() => {
    const fn = async () => {
      const queryParams = new URLSearchParams(location.search)
      const promoCode = queryParams.get("promo_code")

      if (promoCode) {
        await addCouponCode(promoCode)
      }
    }

    if (cart?.discounts?.length === 0) {
      fn()
    }
  }, [cart?.id, location])

  React.useEffect(() => {
    if (checkInterval && gaChecked > 4) {
      clearInterval(checkInterval)
    }
  }, [gaChecked, checkInterval])

  const addCustomFreeShipping = freeShippingLimitThreshold
    ? cart?.gift_card_total > 0 &&
      cart?.tax_total + cart?.subtotal - cart?.discount_total >
        freeShippingLimitThreshold
    : false

  /*React.useEffect(() => {
    const freeShippingOption = async () => {
      if (freeShippingLimitThreshold && cart?.id) {
        await customEndpoints.misc.manageFreeCustomShippingOption({
          cart_id: cart?.id,
          free_shipping_threshold: freeShippingLimitThreshold,
        })
        setIsAboveFreeShippingLimit(addCustomFreeShipping)
      }
    }

    freeShippingOption()
  }, [addCustomFreeShipping, freeShippingLimitThreshold, cart?.id])*/

  const setRegion = async (regionId, countryCode) => {
    const response = await updateCart.mutateAsync({
      region_id: regionId,
      country_code: countryCode,
      shipping_address: {
        address_1: "",
        address_2: "",
        postal_code: "",
        city: "",
        province: "",
      },
    })

    if (response.cart?.region_id) {
      const regionCookieId: string =
        typeof cookies._medusa_region?.id === "string" &&
        cookies._medusa_region?.id
          ? cookies._medusa_region.id
          : undefined

      if (response && regionCookieId !== response.cart?.region_id) {
        setCookies(
          "_medusa_region",
          JSON.stringify({
            id: response.cart.region_id,
            name: response.cart.region.name,
            currency_code: response.cart.region.currency_code,
            country_code: response.cart.shipping_address?.country_code,
          }),
          {
            path: "/",
          }
        )
      }

      await updateFreeShippingLimit(response.cart)
    }
  }

  const createCartWithRegion = async (regionId, countryCode) => {
    const response = await updateCart.mutateAsync({
      context: {
        region_id: regionId,
        country_code: countryCode,
      },
    })

    if (response.cart?.region_id) {
      await updateFreeShippingLimit(response.cart)
    }
  }

  const removeCouponCode = async (couponCode) => {
    const { data } = await customEndpoints.cart.discounts.delete(
      cart?.id,
      couponCode
    )

    if (data?.cart) {
      setCart(data.cart)
    }
  }

  React.useEffect(() => {
    if (cart?.id) {
      localStorage.setItem("medusa::cache", JSON.stringify(cart))
    } else {
      localStorage.removeItem("medusa::cache")
    }
  }, [cart])

  const addCouponCode = async (couponCode) => {
    await updateCart.mutateAsync({
      discounts: [{ code: couponCode }],
    })
  }

  React.useEffect(() => {
    const loadSharedCart = async (cartId: string) => {
      const res = await medusa.client.carts.retrieve(cartId)

      if (
        res?.cart?.id === cart?.id &&
        res?.cart?.subtotal === cart?.subtotal
      ) {
        return
      }

      if (res?.cart && !res.cart.completed_at) {
        setCart(res.cart)
        localStorage.setItem("medusa::cache", JSON.stringify(cart))
      }
    }

    if (cart?.id) {
      const { share } = qs.parse(window.location.search)

      if (share && typeof share === "string") {
        loadSharedCart(share)
      }
    }
  }, [cart])

  return (
    <CartProvider>
      <storeContext.Provider
        value={{
          cart,
          createLineItem,
          removeLineItem,
          updateLineItem,
          addShippingMethod,
          clearCart,
          updateCart,
          startCheckout,
          pay,
          completeCheckout,
          totalItems,
          freeShippingLimitThreshold,
          splittingCarts,
          splitOrderCarts,
          createSplitOrderCarts,
          setRegion,
          createCartWithRegion,
          removeCouponCode,
          addCouponCode,
          noStoreModal,
          setNoStoreModal,
          removeSplitOrderCarts,
          updatePaymentSession,
          isAboveFreeShippingLimit,
        }}
      >
        {children}
      </storeContext.Provider>
    </CartProvider>
  )
}

export const useRawStore = () => React.useContext(storeContext)

export const useStore = () => {
  const store = React.useContext(storeContext)

  if (!store) {
    throw new Error("useStore must be used within a StoreProvider")
  }

  return store
}
